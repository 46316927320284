.additionalInfoSection {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px auto;
  // height: -webkit-fill-available;
  height: auto;

  .additionalInfoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border: 1px solid black;
    border-radius: 10px;
  }
  .downArrow {
    cursor: pointer;
  }

  .additionalInfoContainer {
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
    }
  }
  .form {
    width: 100%;
    margin-left: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 900px) {
  .additionalInfoSection {
    width: 90%;
    min-height: 59vh;
    margin: auto;
    .form {
      margin: auto;
      width: 90%;
      margin-left: 10px;
    }
    .additionalInfoHeader {
      padding: 0px 10px;
      width: 90%;
      font-size: 12px;
    }
    .downArrow {
      size: 20px;
    }
    h5 {
      font-size: 12px;
    }
  }
}
