.formInput {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .inputLabel {
    color: #373636;
    width: fit-content;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }
  .inputBox {
    border-radius: 5px;
    width: 80%;
    height: max-content;
    font-size: 13px;
    border: 1px solid #ccc;
    justify-content: space-between;
    color: rgba(96, 96, 96, 0.8);
    padding: 10px;
    outline: none;
    font-weight: 400;
    display: flex;
    &::placeholder {
      opacity: 0.5;
    }
    transition: border 0.2s ease-in-out;
    &:focus {
      border: 2px solid #555;
    }
  }
  .radioLabel {
    display: flex;
    font-size: 15px;
    align-items: center;
    gap: 5px;
  }
  .radioInputContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 5px;
  }
  .textArea {
    padding: 20px;
    resize: none;
    min-height: 60vh;
    outline: none;
    line-height: 22px;
  }
  .icon {
    cursor: pointer;
  }
}
.inputBox {
  background-color: #fff;
}
@media screen and (max-width: 1050px) {
  .formInput {
    .inputBox {
      width: 90%;
    }
  }
}
@media screen and (max-width: 510px) {
  .formInput {
    .textArea {
      width: 80%;
      min-height: 25vh;
      margin: 10px;
    }
  }
}
