.applicantTableRoot {
  width: 95%;
  gap: 20px;
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

  // grid-template-columns: repeat(auto-fit, minmax(min(35%), 1fr));
  padding: 10px;
  margin-top: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.statusContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.backArrow {
  cursor: pointer;
}

.applicantCard {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-template-columns: repeat(auto-fit, minmax(min(35%), 1fr));
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  gap: 10px;
  width: 100%;
}
.headerOfReview {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}
@media screen and (max-width: 900px) {
  .applicantTableRoot {
    width: 95%;
    height: max-content;
    margin: 0 auto;
  }
  .review {
    width: 100%;
  }
  .cardContainer {
    width: 100%;
    padding: 0;
  }

  .updateButton {
    margin: 0;
  }
  .reviewContainer,
  .updateReviewTextArea,
  .updateReviewContainer,
  .reviewText,
  .textArea {
    width: 100%;
  }
}
