.applicationForm {
  display: flex;
  width: auto;
}
.button {
  border: 1px solid #333333;
  border-radius: 10px;
  background-color: #333333;
  width: 20%;
  height: 40px;
  color: #ffffff;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    color: #333333;
    background-color: #ffffff;
  }
}
.footer,
.header {
  display: flex;
  padding-left: 10px;
  align-items: center;
  margin: 0;
  height: 50px !important;
  padding: 20px 0;
  background-color: #f5f5db;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;
  h3 {
    margin: 0;
    margin-left: 20px;
  }
}
.footerFrame {
  margin-top: auto;
}
.sidebarRoot {
  border-radius: 20px;
  width: 20%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 50px;
  // min-height: 100vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.sidebarHeader {
  padding-left: 10px;
}
.sidebarHeaderLogo {
  display: flex;
  justify-content: center;
}

.sidebarContentContainer {
  border-radius: 20px;
  // height: 100%;
  height: auto;
  padding: 50px 0;
  align-self: center;
  width: 100%;
  background-color: #f5f5db;
}
.sidebarContent {
  height: auto;
  display: flex;
  justify-content: center;
}
.sidebarItems {
  color: #1a3042;
  display: flex;
  flex-direction: column;
  // gap: 50px;
  gap: 20px;
  padding: 0 15px;
}
.sidebarItemContainer {
  display: flex;
  cursor: pointer;
  padding-left: 10px;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  color: "#000";
  &:hover {
    transform: translate(5px, -5px);
    color: #c7a671;
  }
  .sidebarIcon {
    display: flex;
    align-items: center;
  }
  .titleText {
    min-width: fit-content;
    margin-left: 10px;
    font-size: 15px;
  }
}
.clicked {
  color: #c7a671;
}
.addLanguageButton {
  cursor: pointer;
}

.buttonContainer {
  align-items: center;
  display: flex;
  margin: auto;
  height: auto;
  width: 100%;
  justify-content: space-evenly;
}
.inputBoxFile {
  margin: auto;
  width: 90%;
}
.formContent {
  width: 79%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mobilForm {
  display: none;
}

@media screen and (max-width: 900px) {
  .titleText {
    display: none;
  }
  .formContent {
    display: none;
  }
  .sidebarItemContainer {
    align-self: center;
    padding: 10px;
    border-radius: 50%;
  }
  .sidebarContentContainer {
    padding: 0;
  }
  .kanerLogo {
    width: 100%;
  }
  .mobilForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: inherit;
    background-color: #fff;
  }
  .header {
    font-size: 12px;
    width: 100%;
  }
  .sidebarContent {
    display: none;
  }
  .sidebarRoot {
    width: 100%;
    gap: 20px;
  }
  .kanerLogo {
    width: 30%;
  }
  .buttonContainer {
    justify-content: space-between;
    margin: 0 8px;
  }
  .nextButton {
    margin-left: auto;
  }
  .button {
    font-size: 12px;
  }
  .header,
  .footer {
    height: auto !important;
  }
}
@media screen and (max-width: 480px) {
  .kanerLogo {
    width: 40%;
  }
}
