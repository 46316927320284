.education {
  width: 70%;
  float: left;
}
.educationContainer {
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .educationContainer {
    min-height: 59vh;
  }
  .education {
    width: 100%;
    margin: 0;
  }
}
