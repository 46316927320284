.photoZoomRoot {
  position: fixed;
  width: 100%;
  display: flex;
  top: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  .closeIconContainer {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    cursor: pointer;
    .icon {
      margin-left: auto;
      display: flex;
      padding: 10px;
    }
  }
  .imageContainer {
    max-width: 50%;
    display: flex;
    max-height: 50%;
    align-self: center;
  }
}
@media screen and (max-width: 600px) {
  .photoZoomRoot {
    display: none;
  }
}
