.humanResourceRoot {
  .filterComponent {
    width: 35%;
    background-color: #fff;
    gap: 50px;
    min-height: 100vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 20px;
    & h1 {
      background-color: #333333;
      color: #ffffff;
      font-size: 30px;
      line-height: 30px;
      font-weight: 600;
    }
  }

  .hrSideBarRoot {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
    background-color: #f5f5db;
    border-radius: 20px;
  }
  .clicked {
    color: #c7a671;
  }
  .filterBarContainer {
    display: flex;
    justify-content: space-between;
  }
  .filterIcon {
    cursor: pointer;
  }
  .sidebarItemContainer {
    display: flex;
    cursor: pointer;
    padding-left: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    width: 60%;
    margin: auto;
    color: "#000";
    &:hover {
      transform: translate(5px, -5px);
      color: #c7a671;
    }
    .sidebarIcon {
      display: flex;
      align-items: center;
    }
    .titleText {
      min-width: fit-content;
      margin-left: 10px;
      font-size: 15px;
    }
  }
  .sidebarHeaderLogo {
    display: flex;
    justify-content: center;
  }
  .headOfHr {
    display: flex;
    flex-direction: row;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .icon {
      cursor: pointer;
      color: #ffffff;
    }
  }
  .leftBar {
    height: 95vh;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .hr {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  header {
    background-color: #333333;
    padding: 20px;
    color: #ffffff;
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
  }
  .formInput {
    display: inline;
    width: 100%;
  }
  .tabButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    Button {
      margin-top: 20px;
    }
  }
  .searchBar {
    width: 40%;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .searchContainer {
    display: flex;
    // width: 96%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 20px;
  }
  .headerContainer {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: auto;
  }
  .searchInput {
    height: 40px;
    width: 100%;
    margin-left: auto;
  }
  .mobilApplicantTable {
    display: none;
  }
}
.applicantTable {
  width: 100%;
}
.filteringButton {
  width: 96%;
  height: 40px;
}
.filterBarContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.filter {
  display: flex;
  gap: 20px;
}
.filterBar {
  width: 49%;
}
.searchBox {
  width: 70%;
  display: "flex";
  flex-direction: "column";
  gap: 30px;
}
.applicantNumber {
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 5px;
}
@media screen and (max-width: 900px) {
  .applicantTable {
    display: none;
  }
  .exportExcel {
    display: none;
  }

  .humanResourceRoot {
    .mobilApplicantTable {
      display: contents;
    }
    .hrSideBarRoot {
      flex-direction: row;
      gap: 10px;
      display: none;
    }
    .filterComponent {
      width: 100%;
      background-color: #ffffff;
      height: auto;
      box-shadow: none;
      h1 {
        margin-left: 10px;
        color: #333333;
        background-color: transparent;
      }
    }
    .sidebarItemContainer {
      width: auto;
      &:hover {
        transform: none;
      }
      .titleText {
        font-size: 13px;
      }
    }
    .headOfHr {
      width: 100%;
      .icon {
        color: #333333;
        margin-right: 10px;
      }
    }
    .searchBar {
      width: 100%;
    }
  }
}
@media screen and (max-width: 750px) {
  .filteringButton {
    display: none;
  }
  .searchBox {
    display: none;
  }
}
