.reviewModalSection {
  display: flex;
  align-items: center;
  height: auto;
  flex-direction: column;
  align-self: center;
  width: 90%;
  gap: 5px;
  margin: 50px auto;
  border: 1px solid #ddd;
  padding: 10px;
}
.rootOfReview {
  padding: 50px;
}
.addNewCommentButton {
  margin-left: auto;
  display: flex;
  gap: 10px;
}
.updateContainer {
  display: flex;
  gap: 10px;
}
.reviewsForApplicantCard {
  display: grid;
  gap: 20px;
  width: 100%;

  .reviewCard {
    padding: 10px;
    border: 1px solid #eeeeee;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto;
  }
}
.span2 {
  font-size: 15px;
  color: #aaa;
}
.span {
  font-size: 15px;
  font-weight: 700;
}
.textArea {
  height: 400px;
}
.header {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 95%;
  justify-content: space-between;
}
.logoContainer {
  width: 90%;
  margin: auto;
  margin-top: 10px;
}
.icon {
  width: 30px;
}
.left,
.right {
  display: flex;
  gap: 5px;
  align-items: center;
}
.icon {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 1024px) {
  .header {
    flex-direction: column;
    width: 100%;
    align-items: initial;
  }
  .button {
    font-size: 12px;
    height: auto;
  }
  .span,
  .span2 {
    font-size: 12px;
    line-height: 14px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
  .reviewCard {
    gap: 10px;
    p {
      margin: 0;
    }
  }
}
