.personalInfoContainer {
  width: 90%;
  margin: auto;
}
@media screen and (max-width: 510px) {
  .personalInfoContainer {
    min-height: 59vh;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}
