.adminPage {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .label {
    color: #ffffff;
    font-size: 20px;
  }

  .loginInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inputLabel {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    width: 50%;
    color: #ccc;
    gap: 5px;
  }
  .pageContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .input {
    border-radius: 5px;
    width: 90%;
    height: max-content;
    font-size: 13px;
    border: 1px solid #ccc;
    justify-content: space-between;
    color: rgba(96, 96, 96, 0.8);
    padding: 10px;
    outline: none;
    font-weight: 400;
    display: flex;
    &::placeholder {
      opacity: 0.5;
    }
    transition: border 0.2s ease-in-out;
    &:focus {
      border: 2px solid #555;
    }
  }
  .showPasswordButton {
    background: none;
    border: none;
    color: #555; /* Customize the color as needed */
    cursor: pointer;
    font-size: 12px;
    padding: 0;
    margin-top: 8px;
    opacity: 0.5;
    text-decoration: underline;
  }
  .button {
    background-color: #dd9c33;
    width: max-content;
    height: 40px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    transition: background-color 0.5s ease;
    border: none;
    margin-top: 10px;
    &:hover {
      color: #dd9c33;
      background-color: #ffffff;
    }
  }
}
@media screen and (max-width: 900px) {
  .adminPage {
    .input {
      width: 90%;
      height: 10px;
    }
    .inputLabel {
      width: 90%;
      font-size: 12px;
      font-weight: 700;
    }
    .button {
      height: 20px;
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 500px) {
  .adminPage {
    .pageContainer {
      width: 70%;
    }
    .input {
      width: 90%;
    }
    .inputLabel {
      width: 100%;
    }
    .kanerLogo {
      width: 60%;
    }
  }
}
