.applicationInfoContainer {
  height: auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 90%;
  margin: 20px auto;
}
.selectionInput {
  width: 300px;
}
.errorText {
  color: red;
  opacity: 0.84;
  font-style: italic;
  font-size: 10px;
  transform: translateY(-7px);
}

@media screen and (max-width: 1385px) {
  .applicationInfoContainer {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
  }
}
@media screen and (max-width: 900px) {
  .applicationInfoContainer {
    width: 90%;
    min-height: 59vh;
    margin: auto;
  }
  .label {
    font-size: 12px;
    font-weight: 700;
  }
  .selectionInput {
    width: 100%;
    height: 30px;
    align-self: center;
  }
}
