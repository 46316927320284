.languageContainer {
  display: flex;
  // height: -webkit-fill-available;
  height: auto;
  width: 90%;
  flex-direction: column;
  // margin: auto;
  margin: 20px auto;
  // gap: 20px;

  .languageInputBox {
    display: flex;
    width: 95%;
  }
  .inputContainer {
    width: 100%;
    display: flex;
  }
  .icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .buttonContainer {
    width: 200px;
  }
}

@media screen and (max-width: 900px) {
  .languageContainer {
    min-height: 59vh;
    margin: auto;
    .languageInputBox {
      display: flex;
      width: 100%;
    }
    .inputContainer {
      flex-direction: column;
    }
    .buttonContainer {
      width: 30%;
      font-size: 12px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .input {
      width: 100%;
    }
    .label {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
