.careerSection {
  // height: -webkit-fill-available;
  height: auto;

  width: 90%;
  margin: 45px auto;
}
.selection {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.careerSelection {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.errorText {
  color: red;
  opacity: 0.84;
  font-style: italic;
  font-size: 10px;
  transform: translateY(10px);
  margin: 0;
}

@media screen and (max-width: 900px) {
  .label {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
  }
  .selectionInput {
    width: 90%;
    height: 30px;
    align-self: center;
  }
  .selection {
    flex-direction: column;
  }
  .careerSelection {
    width: 100%;
  }
  .errorText {
    color: red;
    opacity: 0.84;
    font-style: italic;
    font-size: 10px;
    transform: translateY(10px);
    margin: 0;
  }

  .careerSection {
    // height: 70vh;
    min-height: 59vh;
    margin: auto;
  }
}
