.cvRoot {
  display: flex;
  align-items: center;
  height: auto;
  flex-direction: column;
  align-self: center;
  width: 90%;
  gap: 5px;
  margin: 50px auto;

  .cvHeader {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  .border {
    border-bottom: 1px solid #ddd;
    width: 100%;
  }
  .sectionHeader {
    text-decoration: underline;
  }
  .section {
    padding: 10px;
  }

  .applicantInfoContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 15px;
  }
  .labelForDepartmentChoices {
    flex-direction: column;
    h5 {
      text-decoration: underline;
      margin-bottom: 10px;
    }
  }
  .applicantExperienceContainer {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .applicantInfoTextContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h5 {
      text-decoration: underline;
      margin: 0;
    }
    span {
      font-size: 14px;
      color: #aaa;
    }
  }
  .labelText,
  .coverLetterText {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0;
    h5 {
      margin: 0;
    }
    span {
      font-size: 14px;
      color: #aaa;
      margin-left: 10px;
    }
  }
  .coverLetterText {
    width: 100%;
    line-height: 20px;
  }
  .cvContainer {
    width: 90%;
    border: 1px solid #ddd;
    position: relative;
  }
  .profileImage {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    overflow: hidden;
    cursor: pointer;
  }
  .headerText {
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    letter-spacing: 8px;
  }

  .link {
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
  .print {
    cursor: pointer;
    background-color: #333;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    color: #ccc;
    border-radius: 10px;
  }
  .footerCv {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .experienceHeader {
    color: black;
    font-weight: 800;
    font-size: 13px;
    text-decoration: underline;
    margin: 0;
  }
  .experienceInfoText {
    width: 25%;
  }
  .experienceContainer {
    display: grid;
    gap: 20px;
  }
}

@media print {
  .cvRoot {
    .noPrint {
      display: none;
    }
  }
}
@media screen and (max-width: 900px) {
  .cvRoot {
    width: 100%;
    .headerText {
      font-size: 15px;
      letter-spacing: 5px;
    }
    .applicantExperienceContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: 1px solid #ddd;
    }
    .experienceInfoText {
      width: 100%;
    }
  }
}
