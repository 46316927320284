.cardContainer {
  padding: 10px;
  max-width: 100%;
  border: 1px solid #eeeeee;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.profileContainer {
  display: flex;
  width: fit-content;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  padding-bottom: 0;
}
.deleteApplicantIcon {
  margin-left: auto;
  cursor: pointer;
}
.nameOfApplicant {
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 5px;
}
.applicantInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 100%;
  // justify-content: space-evenly;
}
.statusContainer {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}
.button {
  font-size: 12px;
  height: auto;
}
.deepInfo {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.location {
  display: flex;
  align-items: center;
  gap: 7px;
}
.smallHeader {
  margin: 0;
}
.buttonContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: auto;
}

@media screen and (max-width: 900px) {
  .cardContainer {
    width: 100%;
    height: 390px;
    padding: 20px 0;
  }

  .profileContainer {
    width: 98%;
  }
}
