.exportButton {
  width: 100%;
  height: 40px;
}

@media screen and (max-width: 900px) {
  .exportButton {
    width: 100%;
  }
}
