.coverLetterContainer {
  height: 100%;
  gap: 30px;
  width: 90%;
  margin: 20px auto;
}
.textArea {
  width: 100%;
  height: 80%;
  resize: none;
}
@media screen and (max-width: 900px) {
  .coverLetterContainer {
    margin: auto;
    min-height: 59vh;
    .textArea {
      height: 300px;
    }
  }
  .textArea {
    font-size: 12px;
    line-height: 18px;
  }
  h6 {
    font-size: 10px;
    line-height: 15px;
    margin: 0 0 10px 0;
  }
}
