.formContainer {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(min(45%), 1fr));
  width: 100%;
  margin: 10px auto 0 auto;
  .formItem {
    display: flex;
  }
  .textArea {
    width: 100%;
    height: 200px;
  }
  .errorText {
    color: red;
    opacity: 0.84;
    font-style: italic;
    font-size: 10px;
    transform: translateY(-7px);
  }
}
.inputField {
  width: 80%;
}
.fileUpload {
  display: inline-block;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  color: #555555;
}
@media screen and (max-width: 1050px) {
  .formContainer {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .fileUpload {
    width: 92%;
    text-align: center;
  }
  .label {
    font-size: 12px;
    font-weight: 700;
  }
  .inputField {
    width: 100%;
    height: 30px;
    font-size: 12px;
  }
  .formContainer {
    .textArea {
      font-size: 12px;
      height: 100px;
    }
  }
}

@media screen and (max-width: 510px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  .input {
    width: 100%;
  }
}
